import React, { useEffect, useState } from 'react';

function Animals() {
  const [animals, setAnimals] = useState([]);
  const [loading, setLoading] = useState(true);

  const organizationNames = {"CA3151": "Shiloh's Rescue", "CA3021": "Novy's Ark", "CA3091": "Atlas Rescue"}

  useEffect(() => {
    const fetchAnimals = async () => {
      try {
        // Add your organization IDs here, separated by commas
        const organizationIds = 'CA3021,CA3151,CA3091';
        const response = await fetch(`https://api.petfinder.com/v2/animals?organization=${organizationIds}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PETFINDER_ACCESS_TOKEN}`
          }
        });
        const data = await response.json();
        setAnimals(data.animals);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching animals:', error);
        setLoading(false);
      }
    };

    fetchAnimals();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <h2>Our Available Animals</h2>
      <p>We do not have any available animals at this time.</p>
      <h2>Available Animals from Other Local Organizations</h2>
      <p>If you are interested in any of the pets below, please reach out directly to these rescues for steps to apply to adopt.</p>
      {loading ? (
        <p>Loading animals...</p>
      ) : (
        <div style={styles.gridContainer}>
          {animals.length > 0 ? (
            animals.map((animal) => (
              <div key={animal.id} style={styles.gridItem}>
                <h3>{animal.name}</h3>
                <p>{animal.species}</p>
                {animal.photos.length > 0 && (
                  <img src={animal.photos[0]?.medium} alt={animal.name} style={styles.image} />
                )}
                <p>Organization: {organizationNames[animal.organization_id]}</p>
                <p>{animal.description}</p>
                <p>Size: {animal.size}</p>
                <p>Age: {animal.age}</p>
                <p>Breed: {animal.breeds.primary}</p>
                <p>Location: {animal.contact.address.city}, {animal.contact.address.state}</p>
                {animal.attributes.house_trained && <p>&#10003; Housetrained</p>}
                {animal.attributes.spayed_neutered && <p>&#10003; Spayed/Neutered</p>}
                {animal.environment.dogs && <p>&#10003; Good with other dogs</p>}
                {animal.environment.cats && <p>&#10003; Good with cats</p>}
                {animal.environment.children && <p>&#10003; Good with children</p>}
                {/* <p><strong>If Interested:</strong></p> */}
                {animal.contact.email && <p>Email: {animal.contact.email}</p>}
                {animal.contact.phone && <p>Phone: {animal.contact.phone}</p>}

              </div>
            ))
          ) : (
            <p>No animals available at the moment.</p>
          )}
        </div>
      )}
    </div>
  );
}

const styles = {
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  gridItem: {
    flex: '1 1 calc(33.333% - 20px)',
    boxSizing: 'border-box',
    margin: '10px',
    padding: '10px',
    border: '1px solid #4C5849',
    borderRadius: '8px',
    maxWidth: '300px',
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
  },
};

export default Animals;
