import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import paddleboard_img from '../assets/images/IMG_2806.jpg';

function Home() {
    return (
        <div style={styles.container}>
            <div style={styles.imageContainer}>
                <img src={paddleboard_img} alt="Paddleboarding with dogs" style={styles.image} />
            </div>
            <div style={styles.textContainer}>
                <h2>Welcome to Paddling Paws!</h2>
                <p><strong>At Paddling Paws, we are dedicated to helping rescue, rehabilitate, and rehome abandoned, neglected, and abused dogs and cats throughout California.</strong> Our mission is to provide each animal with the medical care, loving foster homes, and adoption opportunities they need to thrive and find their forever families.</p>

                <p><strong>But our work goes beyond rescue.</strong> We believe in the power of community outreach and education to promote animal welfare and prevent cruelty. Through our programs, we aim to raise awareness and inspire positive change, helping to create a world where every animal is treated with compassion.</p>

                <p><strong>Paddling Paws is proudly recognized as a 501(c)(3) nonprofit</strong> (EIN 99-4349426), and your donations are tax-deductible. Every contribution helps us save more lives and make a difference in the lives of animals in need.</p>

                <p><strong>We are just getting started October 2024 and are currently focused on organizing community paddle-outs</strong>—fun, community-based events designed to raise funds and awareness for adoptable rescues in our county. As a newly founded nonprofit, we have many dreams and goals for the future, but we're starting small and look forward to expanding our initiatives as we gain support and resources!</p>


                {/* <p>Initiatives we are currently working on:</p>
                <ul>
                    <li><strong>Community Paddle-Outs:</strong> Fun, community-based events to raise funds and awareness for our adoptable rescues.</li>
                    <li><strong>Programming with Pitties:</strong> Twitch/YouTube streams and in-person coding camps, where dogs (especially pit bulls) join in to help promote adoption and raise awareness for misunderstood breeds.</li>
                    <li><strong>Support for Pet Owners Facing Housing Challenges:</strong> We offer guidance and resources to renters and those moving with pets, preventing unnecessary surrenders due to relocation.</li>
                </ul> */}

                <p>If you’re passionate about helping animals or want to learn more about any of our present or future initiatives, we’d love to hear from you! Together, we can make a difference in the lives of countless dogs and cats.</p>

                {/* Add the button here */}
                <Link to="/interest" style={styles.button}>
                    Get Involved
                </Link>

                {/* Social Media & Wishlist Links */}
                <div style={styles.socialContainer}>
                    <a href="https://www.instagram.com/paddling.paws" target="_blank" rel="noopener noreferrer" style={styles.iconButton}>
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>
                    <a href="https://www.youtube.com/@paddlingpaws" target="_blank" rel="noopener noreferrer" style={styles.iconButton}>
                        <FontAwesomeIcon icon={faYoutube} size="2x" />
                    </a>
                    <a href="https://www.amazon.com/registries/gl/guest-view/37QNF6P0LUXZX" target="_blank" rel="noopener noreferrer" style={styles.iconButton}>
                        <FontAwesomeIcon icon={faShoppingCart} size="2x" />
                    </a>
                </div>

            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px',
        flexWrap: 'wrap',
    },
    imageContainer: {
        flex: '1',
        minWidth: '300px',
    },
    textContainer: {
        flex: '2',
        marginLeft: '20px',
        minWidth: '300px',
    },
    image: {
        width: '100%',
        maxWidth: '400px',
    },
    button: {
        display: 'inline-block',
        padding: '10px 20px',
        backgroundColor: '#4C5849',
        color: '#fff',
        textDecoration: 'none',
        fontSize: '16px',
        borderRadius: '5px',
        marginTop: '20px',
        textAlign: 'center',
        transition: 'background-color 0.3s',
    },
    socialContainer: {
        display: 'flex',
        // justifyContent: 'space-around',
        marginTop: '20px',
    },
    iconButton: {
        color: '#4C5849',
        textDecoration: 'none',
        padding: '10px',
        borderRadius: '50%',
        transition: 'background-color 0.3s',
    },
    '@media (maxWidth: 768px)': {
        container: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        textContainer: {
            marginLeft: '0px',
            marginTop: '20px',
        },
    },
};

export default Home;
